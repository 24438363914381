import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CompanyService } from 'src/app/services/companies-services/company.service';
import { CompanyModel, UpdateCompanyModel } from 'src/app/models/company.model';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';

@Component({
  selector: 'app-companies-company-info-card',
  templateUrl: './companies-company-info-card.component.html',
  styleUrls: ['./companies-company-info-card.component.scss']
})
export class CompaniesCompanyInfoCardComponent implements OnInit, OnChanges {

  @Input() company: CompanyModel;

  userCreationFormErrorMessage: string;
  editCompanyForm = new FormGroup({
    active: new FormControl(null),
    companyInternalId: new FormControl(null, Validators.required),
    companyComercialName: new FormControl(null, Validators.required),
    companyCif: new FormControl({value: null, disabled: true}, Validators.required),
    companyFiscalName: new FormControl(null, Validators.required),
    companyAddress: new FormControl(null),
    companyCity: new FormControl(null),
    companyPostalCode: new FormControl(null),
    companyProvince: new FormControl(null),
    companyRegion: new FormControl(null),
    companyCountry: new FormControl(null, Validators.required),
    companyFax: new FormControl(null),
    companyPhone: new FormControl(null),
    companyWeb: new FormControl(null),
    companyEmail: new FormControl(null)
  });

  updateCompanySubscription: Subscription;

  constructor(private companyService: CompanyService) { }

  ngOnInit() {}

  ngOnChanges() {
    if (this.company) {
      this.resetEditCompanyForm(this.company);
    }
  }

  private resetEditCompanyForm(data: CompanyModel) {
    this.editCompanyForm.patchValue({
      active: data.active,
      companyInternalId: data.internalId,
      companyComercialName: data.companyName,
      companyCif: data.companyCif,
      companyFiscalName: data.fiscalName,
      companyAddress: data.street,
      companyCity: data.city,
      companyPostalCode: data.zipCode,
      companyProvince: data.province,
      companyRegion: data.region,
      companyCountry: data.country,
      companyFax: data.faxNumber,
      companyPhone: data.phoneNumber,
      companyWeb: data.companyWeb,
      companyEmail: data.email
    });
  }

  public updateCompany() {
    const company: UpdateCompanyModel = {
      active: true,
      internalId: this.editCompanyForm.get('companyInternalId').value,
      companyName: this.editCompanyForm.get('companyComercialName').value,
      companyCif: this.editCompanyForm.get('companyCif').value,
      fiscalName: this.editCompanyForm.get('companyFiscalName').value,
      street: this.editCompanyForm.get('companyAddress').value,
      city: this.editCompanyForm.get('companyCity').value,
      zipCode: this.editCompanyForm.get('companyPostalCode').value,
      province: this.editCompanyForm.get('companyProvince').value,
      region: this.editCompanyForm.get('companyRegion').value,
      country: this.editCompanyForm.get('companyCountry').value,
      faxNumber: this.editCompanyForm.get('companyFax').value,
      phoneNumber: this.editCompanyForm.get('companyPhone').value,
      companyWeb: this.editCompanyForm.get('companyWeb').value,
      email: this.editCompanyForm.get('companyEmail').value
    };
    this.updateCompanySubscription = this.companyService.updateCompany(company).pipe(
      tap(res => this.companyService.refreshCompany())
    ).subscribe();
  }

  public hasError(field) {
    const formControl = this.editCompanyForm.get(field);
    if (formControl.touched && formControl.invalid) {
      if (formControl.errors.required) {
        return '* Campo obligatorio';
      }
      return '* Campo ' + field + ' inválido';
    }
    return false;
  }
}
