import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-licenses-licenses',
  templateUrl: './licenses-licenses.component.html',
  styleUrls: ['./licenses-licenses.component.scss']
})
export class LicensesLicensesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
