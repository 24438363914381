import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserStorageService } from 'src/app/services/user-storage-service/user-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserAccessGuard implements CanActivate {

  constructor(private userStorageService: UserStorageService,
              private router: Router) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.userStorageService.checkSession()) {
        return true;
    } else {
      this.router.navigate(['/signin']);
      return false;
    }
  }
}
