import {Component, Input, OnInit} from '@angular/core';
import {ProductModel} from "../../../models/product.model";
import {ApplicationModel} from "../../../models/application.model";
import {CompanyModel, UpdateCompanyModel} from "../../../models/company.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  LicenseApplicationModel, LicenseCenterModel,
  LicenseCompanyModel,
  LicenseModel,
  LicenseNewModel
} from "../../../models/license.model";
import {tap} from "rxjs/internal/operators/tap";
import {catchError} from "rxjs/internal/operators/catchError";
import {empty, Subscription} from "rxjs";
import {LicenseService} from "../../../services/licenses-service/license.service";

@Component({
  selector: 'app-licenses-new-license-creation-card',
  templateUrl: './licenses-new-license-creation-card.component.html',
  styleUrls: ['./licenses-new-license-creation-card.component.scss']
})
export class LicensesNewLicenseCreationCardComponent implements OnInit {

  @Input() companies: LicenseCompanyModel[];
  @Input() centers: LicenseCenterModel[];
  @Input() applications: LicenseApplicationModel[];

  addNewCompanySubscription: Subscription;
  licenseCreationFormErrorMessage: string;
  createdLicense: LicenseModel;
  selectedCompany: boolean;

  newLicenseForm = new FormGroup({
    active: new FormControl(true),
    licenseDescription: new FormControl(null, Validators.required),
    licenseCompany: new FormControl(null, Validators.required),
    licenseMultiCenter: new FormControl(true),
    licenseCenter: new FormControl({value: null, disabled: true}),
    licenseMultiApp: new FormControl(true),
    licenseApplication: new FormControl({value: null, disabled: true}),
    licenseStartDateMillis: new FormControl(null, Validators.required),
    licenseEndDateMillis: new FormControl(null, Validators.required),
    licenseQuantity: new FormControl(null, Validators.required)
  });

  constructor(private licenseService: LicenseService) { }

  ngOnInit() {
    this.valueChanges()
  }

  public valueChanges() {
    this.newLicenseForm.valueChanges.pipe(
      tap(o => {
        let company = this.newLicenseForm.get('licenseCompany').value;
        if (company && company.internalId) {
          this.selectedCompany = true
          this.getCenters(company.internalId)
          this.getApplications(company.internalId)
        } else {
          this.selectedCompany = false
        }
      })
    ).subscribe();
  }

  public getCenters(internalId: string) {
    this.licenseService.getCenters(internalId).pipe(
      tap((res: LicenseCenterModel[]) => this.centers = res)
    ).subscribe();
  }

  public getApplications(internalId: string) {
    this.licenseService.getApplications(internalId).pipe(
      tap((res: LicenseApplicationModel[]) => this.applications = res)
    ).subscribe();
  }

  public addNewLicense() {
    this.licenseCreationFormErrorMessage = '';
    const validation = this.validation();
    if (validation === true) {
      let centerInternalId = null
      const multiCenter = this.newLicenseForm.get('licenseMultiCenter').value
      if(!multiCenter) {
        centerInternalId = this.newLicenseForm.get('licenseCenter').value.internalId
      }
      let applicationInternalId = null
      const multiApp = this.newLicenseForm.get('licenseMultiApp').value
      if(!multiApp) {
        applicationInternalId = this.newLicenseForm.get('licenseApplication').value.internalId
      }
      const license: LicenseNewModel = {
        companyInternalId: this.newLicenseForm.get('licenseCompany').value.internalId,
        applicationInternalId,
        centerInternalId,
        description: this.newLicenseForm.get('licenseDescription').value,
        quantity: this.newLicenseForm.get('licenseQuantity').value,
        startDateMillis: new Date(this.newLicenseForm.get('licenseStartDateMillis').value).getTime(),
        endDateMillis: new Date(this.newLicenseForm.get('licenseEndDateMillis').value).getTime(),
        multiCenter,
        multiApp,
        active: this.newLicenseForm.get('active').value
      };
      this.addNewCompanySubscription = this.licenseService.addNewLicense(license).pipe(
        tap(res => this.createdLicense = res),
        catchError(err => {
          this.licenseCreationFormErrorMessage = err;
          return empty();
        })
      ).subscribe();
    }
  }

  public toggleMultiCenter() {
    if(this.newLicenseForm.get('licenseMultiCenter').value) {
      this.newLicenseForm.get('licenseCenter').disable();
    } else {
      this.newLicenseForm.get('licenseCenter').enable();
    }
  }

  public toggleMultiApp() {
    if(this.newLicenseForm.get('licenseMultiApp').value) {
      this.newLicenseForm.get('licenseApplication').disable();
    } else {
      this.newLicenseForm.get('licenseApplication').enable();
    }
  }

  public clearField(fieldName: string) {
    this.newLicenseForm.patchValue({
      [fieldName]: null
    });
  }

  private validation() {
    if (this.newLicenseForm.valid) {
      return true;
    }
    this.newLicenseForm.markAllAsTouched();
  }

  public hasError(field) {
    const formControl = this.newLicenseForm.get(field);
    if (formControl.touched && formControl.invalid) {
      if (formControl.errors.required) {
        return '* Campo obligatorio';
      }
      return '* Campo ' + field + ' inválido';
    }
    return false;
  }

}
