import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mb-card',
  templateUrl: './mb-card.component.html',
  styleUrls: ['./mb-card.component.scss']
})
export class MbCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
