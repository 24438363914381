import { Component, OnInit, Input, ContentChild, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-mb-collapse',
  templateUrl: './mb-collapse.component.html',
  styleUrls: ['./mb-collapse.component.scss']
})
export class MbCollapseComponent implements OnInit {

  @Input() preicon: string;
  @Input() content: string;
  @ViewChild('child', {static: true, read: false}) child: ElementRef;
  o = false;


  constructor() { }

  ngOnInit() {
  }

  public open() {
    this.o = !this.o;
  }

  public hasChilds() {
    if (this.child && (this.child.nativeElement.childNodes.length > 0)) {
        return true;
    }
    return false;
  }
}
