import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[flat]'
})
export class MbButtonFlatDirective  implements AfterViewInit {

  constructor(private eRef: ElementRef, private r2: Renderer2) { }

  ngAfterViewInit() {
    this.r2.addClass(this.eRef.nativeElement.firstChild, 'flat');
  }

}
