import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from 'src/app/services/companies-services/company.service';
import { tap } from 'rxjs/internal/operators/tap';
import { CompanyModel } from 'src/app/models/company.model';
import { ProductModel } from 'src/app/models/product.model';
import { ApplicationModel } from 'src/app/models/application.model';

@Component({
  selector: 'app-companies-company-dashboard',
  templateUrl: './companies-company-dashboard.component.html',
  styleUrls: ['./companies-company-dashboard.component.scss']
})
export class CompaniesCompanyDashboardComponent implements OnInit {

  company: CompanyModel;
  products: ProductModel[];
  applications: ApplicationModel[];
  companyProducts: ProductModel[];
  companyApplications: ApplicationModel[];

  constructor(private activatedRoute: ActivatedRoute,
              private companyService: CompanyService) { }

  ngOnInit() {
    this.listenRefreshCompany();
    this.getCompany();
    this.getProductList();
    this.getApplicationList();
  }

  private getCompany() {
    const companyId = this.activatedRoute.snapshot.params['companyId'];
    this.companyService.getCompany(companyId).pipe(
      tap((res: CompanyModel) => this.company = res),
      tap(res => this.getCompanyProductList(this.company.internalId)),
      tap(res => this.getCompanyApplicationList(this.company.internalId))
    ).subscribe();
  }

  private listenRefreshCompany() {
    this.companyService.listenRefreshCompany().subscribe(
      (res) => {
        this.getCompany();
      }
    );
  }

  public getProductList() {
    this.companyService.getProductList().pipe(
      tap((res: ProductModel[]) => this.products = res)
    ).subscribe();
  }

  public getCompanyProductList(internalId) {
    this.companyService.getCompanyProductList(internalId).pipe(
      tap((res: ProductModel[]) => this.companyProducts = res)
    ).subscribe();
  }

  public getApplicationList() {
    this.companyService.getApplicationList().pipe(
      tap((res: ApplicationModel[]) => this.applications = res)
    ).subscribe();
  }

  public getCompanyApplicationList(internalId) {
    this.companyService.getCompanyApplicationList(internalId).pipe(
      tap((res: ApplicationModel[]) => this.companyApplications = res)
    ).subscribe();
  }

}
