import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DataModel } from 'src/app/models/data.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyDaoService {

  private BASE_URL = environment.apiURL;
  private OPTIONS = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8'
    })
  };

  constructor(private http: HttpClient) { }

  public getCompanies() {
    const options = {};
    Object.assign(options, this.OPTIONS);
    const item = null;
    return this.http.post<DataModel>(this.BASE_URL + 'private/v2.0/companyFormMaintenance/ws_getCompanyList', null, options);
  }
  public getCompany(internalId) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    const item = { internalId };
    return this.http.post<DataModel>(this.BASE_URL + 'private/v2.0/companyFormMaintenance/ws_getCompanyDetails', item, options);
  }
  public addNewCompany(companyData, centerData, userAdminData) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    const item = { companyData, centerData, userAdminData };
    return this.http.post<DataModel>(this.BASE_URL + 'private/v2.0/companyFormMaintenance/ws_saveCompany', item, options);
  }
  public updateCompany(companyName, internalId, fiscalName, street, city, zipCode, country, province, region, faxNumber, phoneNumber, companyWeb, email) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    const item = { companyName, internalId, fiscalName, street, city, zipCode, country, province, region, faxNumber, phoneNumber, companyWeb, email };
    return this.http.post<DataModel>(this.BASE_URL + 'private/v2.0/companyFormMaintenance/ws_editCompany', item, options);
  }
  public updateCompanyService(internalId, serviceType, serviceActive) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    const item = { internalId, serviceType, serviceActive };
    return this.http.post<DataModel>(this.BASE_URL + 'private/v2.0/companyFormMaintenance/ws_editCompanyService', item, options);
  }
  public updateCompanyImage(internalId, imageRaw, size) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    const item = { internalId, imageRaw, size };
    return this.http.post<DataModel>(this.BASE_URL + 'private/v2.0/companyFormMaintenance/ws_updateImageCompany', item, options);
  }
  public deleteCompany(internalId) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    const item = { internalId };
    return this.http.post<DataModel>(this.BASE_URL + 'private/v2.0/companyFormMaintenance/ws_deleteCompany', item, options);
  }
  public getProductList() {
    const options = {};
    Object.assign(options, this.OPTIONS);
    const item = { };
    return this.http.post<DataModel>(this.BASE_URL + 'private/v2.0/companyFormMaintenance/ws_getMproductList', item, options);
  }
  public getCompanyProductList(internalId) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    const item = { internalId };
    return this.http.post<DataModel>(this.BASE_URL + 'private/v2.0/companyFormMaintenance/ws_getCompanyMproductList', item, options);
  }
  public updateCompanyProduct(internalId, serviceType, serviceActive) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    const item = { internalId, serviceType, serviceActive };
    return this.http.post<DataModel>(this.BASE_URL + 'private/v2.0/companyFormMaintenance/ws_editCompanyMProduct', item, options);
  }
  public getApplicationList() {
    const options = {};
    Object.assign(options, this.OPTIONS);
    const item = { };
    return this.http.post<DataModel>(this.BASE_URL + 'private/v2.0/companyFormMaintenance/ws_getApplicationList', item, options);
  }
  public getCenterTypeList() {
    const options = {};
    Object.assign(options, this.OPTIONS);
    const item = { };
    return this.http.post<DataModel>(this.BASE_URL + 'private/v2.0/companyFormMaintenance/ws_getCenterTypeList', item, options);
  }
  public getCompanyApplicationList(internalId) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    const item = { internalId };
    return this.http.post<DataModel>(this.BASE_URL + 'private/v2.0/companyFormMaintenance/ws_getCompanyApplicationList', item, options);
  }
  public updateCompanyApplication(internalId, serviceType, serviceActive) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    const item = { internalId, serviceType, serviceActive };
    return this.http.post<DataModel>(this.BASE_URL + 'private/v2.0/companyFormMaintenance/ws_editCompanyApplication', item, options);
  }
}
