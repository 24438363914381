import {Injectable, isDevMode} from '@angular/core';
import {tap} from "rxjs/internal/operators/tap";
import {DataModel} from "../../models/data.model";
import {map} from "rxjs/internal/operators/map";
import {LicenseDaoService} from "../../dao/licenses-dao-services/license-dao.service";
import {catchError} from "rxjs/internal/operators/catchError";
import {Subject, throwError} from "rxjs";
import {LicenseModel, LicenseNewModel, LicenseUpdateModel} from "../../models/license.model";
import {UpdateCompanyModel} from "../../models/company.model";

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  private refreshLicenseListener = new Subject<any>();
  private refreshLicensesListener = new Subject<any>();

  constructor(private licenseDaoService: LicenseDaoService) { }

  public listenRefreshLicense() {
    return this.refreshLicenseListener.asObservable();
  }

  public refreshLicense() {
    this.refreshLicenseListener.next();
  }

  public listenRefreshLicenses() {
    return this.refreshLicensesListener.asObservable();
  }

  public refreshLicenses() {
    this.refreshLicensesListener.next();
  }

  public getLicenses() {
    return this.licenseDaoService.getLicenses().pipe(
      tap((res: DataModel) => {if (isDevMode()) { console.dir(res); }}),
      map((res: DataModel) => res['data']['licenseList'])
    );
  }

  public getLicense(internalId: string) {
    return this.licenseDaoService.getLicense(internalId).pipe(
      tap((res: DataModel) => {if (isDevMode()) { console.dir(res); }}),
      map((res: DataModel) => res['data']['license'])
    );
  }

  public getCompanies() {
    return this.licenseDaoService.getCompanies().pipe(
      tap((res: DataModel) => {if (isDevMode()) { console.dir(res); }}),
      map((res: DataModel) => res['data']['companyList'])
    );
  }

  public getCenters(companyInternalId: string) {
    return this.licenseDaoService.getCenters(companyInternalId).pipe(
      tap((res: DataModel) => {if (isDevMode()) { console.dir(res); }}),
      map((res: DataModel) => res['data']['companyCenterList'])
    );
  }

  public getApplications(companyInternalId: string) {
    return this.licenseDaoService.getApplications(companyInternalId).pipe(
      tap((res: DataModel) => {if (isDevMode()) { console.dir(res); }}),
      map((res: DataModel) => res['data']['companyApplicationList'])
    );
  }
  public addNewLicense(newLicense: LicenseNewModel) {
    return this.licenseDaoService.addNewLicense(
        newLicense.companyInternalId,
        newLicense.applicationInternalId,
        newLicense.centerInternalId,
        newLicense.description,
        newLicense.quantity,
        newLicense.startDateMillis,
        newLicense.endDateMillis,
        newLicense.multiCenter,
        newLicense.multiApp,
        newLicense.active
      ).pipe(
      tap((res: DataModel) => {if (isDevMode()) { console.dir(res); }}),
      map((res: DataModel) => res['data']['license']),
      catchError(error => {
          return throwError('Error');
        }
      )
    );
  }

  public updateLicense(license: LicenseUpdateModel) {
    return this.licenseDaoService.updateLicense(
      license.internalId,
      license.companyInternalId,
      license.applicationInternalId,
      license.centerInternalId,
      license.description,
      license.quantity,
      license.startDateMillis,
      license.endDateMillis,
      license.multiCenter,
      license.multiApp,
      license.active
    ).pipe(
      tap((res: DataModel) => {if (isDevMode()) { console.dir(res); }}),
      map((res: DataModel) => res['data']['success'])
    );
  }

  public deleteLicense(internalId: string) {
    return this.licenseDaoService.deleteLicense(internalId).pipe(
      tap((res: DataModel) => {if (isDevMode()) { console.dir(res); }}),
      map((res: DataModel) => res['data']['success'])
    );
  }

}
