import {Component, OnInit} from '@angular/core';
import {CompanyService} from "../../../services/companies-services/company.service";
import {tap} from "rxjs/internal/operators/tap";
import {ApplicationModel} from "../../../models/application.model";
import {LicenseApplicationModel, LicenseCompanyModel} from "../../../models/license.model";
import {LicenseService} from "../../../services/licenses-service/license.service";

@Component({
  selector: 'app-licenses-new-license-dashboard',
  templateUrl: './licenses-new-license-dashboard.component.html',
  styleUrls: ['./licenses-new-license-dashboard.component.scss']
})
export class LicensesNewLicenseDashboardComponent implements OnInit {

  companies: LicenseCompanyModel[];

  constructor(private licenseService: LicenseService) { }

  ngOnInit() {
    this.getCompanies();
  }

  public getCompanies() {
    this.licenseService.getCompanies().pipe(
      tap((res: LicenseCompanyModel[]) => this.companies = res)
    ).subscribe();
  }

}
