import { Component, OnInit, ElementRef, ViewChild, Input, ChangeDetectorRef, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CompanyService } from 'src/app/services/companies-services/company.service';
import { Subscription, Observable, empty } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/internal/operators/catchError';
import { ProductModel } from 'src/app/models/product.model';
import { ApplicationModel } from 'src/app/models/application.model';
import { CenterTypeModel } from 'src/app/models/centertype.model';
import { TIMEZONES } from 'src/assets/timezones/timezones';

declare var google;

@Component({
  selector: 'app-companies-new-company-creation-card',
  templateUrl: './companies-new-company-creation-card.component.html',
  styleUrls: ['./companies-new-company-creation-card.component.scss']
})
export class CompaniesNewCompanyCreationCardComponent implements OnInit, OnChanges {

  @ViewChild('companyUploadInput', { static: false }) companyUploadInput: ElementRef;
  @ViewChild('centerUploadInput', { static: false }) centerUploadInput: ElementRef;
  @ViewChild('map', { static: false }) map: ElementRef;
  @Input() countries: string[];
  @Input() products: ProductModel[];
  @Input() applications: ApplicationModel[];
  @Input() centerTypes: CenterTypeModel[];
  @Input() timezones;
  showProductsForm;
  showApplicationsForm;

  userCreationFormErrorMessage: string;
  addNewCompanySubscription: Subscription;
  mapInstance;
  showMap;
  createdCompany;
  loading: boolean;
  logCreatingCompany = [];
  logCreatingProducts = [];
  logCreatingApplications = [];

  constructor(private companyService: CompanyService,
              private cd: ChangeDetectorRef) {
              }

  productsForm = new FormGroup({});
  applicationsForm = new FormGroup({});
  newCompanyForm = new FormGroup({
    active: new FormControl(true),
    companyComercialName: new FormControl(null, Validators.required),
    companyCif: new FormControl(null, Validators.required),
    companyFiscalName: new FormControl(null, Validators.required),
    companyAddress: new FormControl(null),
    companyCity: new FormControl(null),
    companyPostalCode: new FormControl(null),
    companyProvince: new FormControl(null),
    companyRegion: new FormControl(null),
    companyCountry: new FormControl(null, Validators.required),
    companyFax: new FormControl(null),
    companyPhone: new FormControl(null),
    companyWeb: new FormControl(null),
    companyEmail: new FormControl(null, Validators.email),
    companyImageRaw: new FormControl(null, Validators.required),
    companyImageType: new FormControl(null, Validators.required),
    userEmail: new FormControl(null, [Validators.required, Validators.email]),
    userPassword: new FormControl(null, Validators.required),
    userFirstname: new FormControl(null, Validators.required),
    userLastname: new FormControl(null, Validators.required),
    centerComercialName: new FormControl(null, Validators.required),
    centerCode: new FormControl(null),
    centerCif: new FormControl(null),
    centerFiscalName: new FormControl(null),
    centerFiscalAddress: new FormControl(null),
    centerAddress: new FormControl(null, Validators.required),
    centerCity: new FormControl(null, Validators.required),
    centerPostalCode: new FormControl(null, Validators.required),
    centerProvince: new FormControl(null, Validators.required),
    centerRegion: new FormControl(null, Validators.required),
    centerCountry: new FormControl(null, Validators.required),
    centerType: new FormControl(null, Validators.required),
    centerLatitude: new FormControl(null, Validators.required),
    centerLongitude: new FormControl(null, Validators.required),
    centerActive: new FormControl(true),
    centerVisible: new FormControl(true),
    centerImageRaw: new FormControl(null, Validators.required),
    centerImageType: new FormControl(null, Validators.required),
    centerUserFax: new FormControl(null),
    centerUserPhone: new FormControl(null, Validators.required),
    centerUserMail: new FormControl(null, [Validators.required, Validators.email]),
    centerUserContact: new FormControl(null, Validators.required),
    centerTimezone: new FormControl({ value: 'Europe/Madrid' }, Validators.required)
  });

  ngOnInit() { }

  ngOnChanges() {
    if (this.products && this.applications && (this.products.length > 0) && (this.applications.length > 0)) {
      this.setProductsForm();
      this.setApplicationsForm();
    }
  }

  public addNewCompany() {
    this.userCreationFormErrorMessage = '';
    const validation = this.validation();
    if (validation === true) {
      this.loading = true;
      this.logCreatingCompany.push('Creando datos básicos de compañia...');
      this.addNewCompanySubscription = this.companyService.addNewCompany(this.newCompanyForm).pipe(
        tap(res => this.logCreatingCompany.push('Compañia creada')),
        tap(res => this.createdCompany = res),
        tap(res => Object.entries(this.productsForm.value).forEach(p => {
          if (p[1]) {
            this.logCreatingProducts.push('Añadiendo producto ' + p[0] + '...');
            this.companyService.updateCompanyProduct(res.internalId, p[0], p[1]).pipe(tap(o => this.logCreatingProducts.push('Añadido'))).subscribe();
          }
        })),
        tap(res => Object.entries(this.applicationsForm.value).forEach(a => {
          if (a[1]) {
            this.logCreatingApplications.push('Añadiendo aplicación ' + a[0] + '...');
            this.companyService.updateCompanyApplication(res.internalId, a[0], a[1]).pipe(tap(o => this.logCreatingApplications.push('Añadido'))).subscribe();
          }
        })),
        catchError(err => {
          this.userCreationFormErrorMessage = err;
          this.loading = true;
          return empty();
        })
      ).subscribe();
    }
  }

  public getActiveProducts() {
    const enabledProducts = [];
    if (this.productsForm && (Object.entries(this.productsForm.value).length > 0)) {
      Object.entries(this.productsForm.value).forEach(c => {
        if (c[1]) {
          enabledProducts.push(this.products.find(p => p.mproductCode === c[0]));
        }
      });
      return enabledProducts;
    }
    return [];
  }

  private validation() {
    if (this.newCompanyForm.valid) {
      return true;
    }
    this.newCompanyForm.markAllAsTouched();
  }

  public companyUploadInputChanged(event) {
    const files = this.companyUploadInput.nativeElement.files;
    this.toBase64(files[0]).subscribe(
      (res) => {
        this.newCompanyForm.patchValue({
          companyImageType: 'IMAGE',
          companyImageRaw: res
        });
      }
    );
  }
  public centerUploadInputChanged(event) {
    const files = this.centerUploadInput.nativeElement.files;
    this.toBase64(files[0]).subscribe(
      (res) => {
        this.newCompanyForm.patchValue({
          centerImageType: 'IMAGE',
          centerImageRaw: res
        });
      }
    );
  }

  private toBase64(file) {
    return new Observable(
      (o) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          o.next(reader.result);
        };
        reader.onerror = (error) => {
          o.error(error);
        };
      }
    );
  }

  public hasError(field) {
    const formControl = this.newCompanyForm.get(field);
    if (formControl.touched && formControl.invalid) {
      if (formControl.errors.required) {
        return '* Campo obligatorio';
      }
      return '* Campo ' + field + ' inválido';
    }
    return false;
  }

  public onAddressChange(e) {
    let address = '';
    this.newCompanyForm.get('centerAddress').value ? address += this.newCompanyForm.get('centerAddress').value + ', ' : address += '';
    this.newCompanyForm.get('centerCity').value ? address += this.newCompanyForm.get('centerCity').value + ', ' : address += '';
    this.newCompanyForm.get('centerPostalCode').value ? address += this.newCompanyForm.get('centerPostalCode').value + ', ' : address += '';
    this.newCompanyForm.get('centerProvince').value ? address += this.newCompanyForm.get('centerProvince').value + ', ' : address += '';
    this.newCompanyForm.get('centerRegion').value ? address += this.newCompanyForm.get('centerRegion').value + ', ' : address += '';
    this.newCompanyForm.get('centerCountry').value ? address += this.newCompanyForm.get('centerCountry').value + ', ' : address += '';

    this.companyService.getGpsLocationFromAddress(address).pipe(
      tap(res => this.newCompanyForm.patchValue({
        centerLatitude: res['lat'],
        centerLongitude: res['lng']
      })),
      tap(res => this.initMap())
    ).subscribe();
  }

  public onGPSChange(e) {
    this.initMap();
  }

  private setProductsForm() {
    this.products.forEach((p: ProductModel) => {
      const control = new FormControl(false);
      this.productsForm.addControl(p.mproductCode, control);
    });
    this.showProductsForm = true;
  }

  private setApplicationsForm() {
    this.applications.forEach((a: ApplicationModel) => {
      const control = new FormControl(false);
      this.applicationsForm.addControl(a.applicationName, control);
    });
    this.showApplicationsForm = true;
  }

  private initMap() {
    this.showMap = true;
    this.cd.detectChanges();
    this.mapInstance = new google.maps.Map(this.map.nativeElement, {
      zoom: 18,
      center: new google.maps.LatLng(this.newCompanyForm.get('centerLatitude').value, this.newCompanyForm.get('centerLongitude').value),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false });
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(this.newCompanyForm.get('centerLatitude').value, this.newCompanyForm.get('centerLongitude').value),
      map: this.mapInstance });
  }

  public setPreselected2(op1, op2) {
    if (op1 && op2) {
      return op1 === op2;
    }
  }

}
