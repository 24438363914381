import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {
  LicenseApplicationModel,
  LicenseCenterModel,
  LicenseModel,
  LicenseNewModel, LicenseUpdateModel
} from "../../../models/license.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CompanyModel, UpdateCompanyModel} from "../../../models/company.model";
import {LicenseService} from "../../../services/licenses-service/license.service";
import {tap} from "rxjs/internal/operators/tap";
import {Subscription} from "rxjs";
import * as moment from 'moment/moment';

@Component({
  selector: 'app-licenses-license-info-card',
  templateUrl: './licenses-license-info-card.component.html',
  styleUrls: ['./licenses-license-info-card.component.scss']
})
export class LicensesLicenseInfoCardComponent implements OnInit, OnChanges {

  @Input() license: LicenseModel;
  @Input() centers: LicenseCenterModel[];
  @Input() applications: LicenseApplicationModel[];

  licenseEditFormErrorMessage: string;
  editLicenseForm = new FormGroup({
    active: new FormControl(true),
    licenseInternalId: new FormControl(null, Validators.required),
    licenseDescription: new FormControl(null, Validators.required),
    licenseCompanyInternalId: new FormControl(null, Validators.required),
    licenseCompanyName: new FormControl({value: null, disabled: true}, Validators.required),
    licenseMultiCenter: new FormControl(true),
    licenseCenter: new FormControl({value: null, disabled: true}),
    licenseMultiApp: new FormControl(true),
    licenseApplication: new FormControl({value: null, disabled: true}),
    licenseStartDateMillis: new FormControl(null, Validators.required),
    licenseEndDateMillis: new FormControl(null, Validators.required),
    licenseQuantity: new FormControl(null, Validators.required)
  });

  updateLicenseSubscription: Subscription;

  constructor(private licenseService: LicenseService) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.license) {
      this.resetEditLicenseForm(this.license);
    }
  }

  private resetEditLicenseForm(data: LicenseModel) {
    let selectedCenter = this.centers.find(c => c.internalId === data.centerInternalId);
    if( selectedCenter ) {
      this.editLicenseForm.get('licenseCenter').enable();
    }
    let selectedApplication = this.applications.find(a => a.internalId === data.applicationInternalId);
    if( selectedApplication ) {
      this.editLicenseForm.get('licenseApplication').enable();
    }
    this.editLicenseForm.patchValue({
      active: data.active,
      licenseInternalId: data.internalId,
      licenseDescription: data.description,
      licenseCompanyInternalId: data.companyInternalId,
      licenseCompanyName: data.companyName,
      licenseMultiCenter: data.multiCenter,
      licenseCenter: selectedCenter,
      licenseMultiApp: data.multiApp,
      licenseApplication: selectedApplication,
      licenseStartDateMillis: moment(data.startDateMillis),
      licenseEndDateMillis: moment(data.endDateMillis),
      licenseQuantity: data.quantity
    });
  }

  public toggleMultiCenter() {
    if(this.editLicenseForm.get('licenseMultiCenter').value) {
      this.editLicenseForm.get('licenseCenter').disable();
    } else {
      this.editLicenseForm.get('licenseCenter').enable();
    }
  }

  public toggleMultiApp() {
    if(this.editLicenseForm.get('licenseMultiApp').value) {
      this.editLicenseForm.get('licenseApplication').disable();
    } else {
      this.editLicenseForm.get('licenseApplication').enable();
    }
  }

  public clearField(fieldName: string) {
    this.editLicenseForm.patchValue({
      [fieldName]: null
    });
  }

  public setPreselected(op1, op2) {
    if (op1 && op2) {
      return op1.internalId === op2.internalId;
    }
  }

  public updateLicense() {
    let centerInternalId = null
    const multiCenter = this.editLicenseForm.get('licenseMultiCenter').value
    if(!multiCenter) {
      centerInternalId = this.editLicenseForm.get('licenseCenter').value.internalId
    }
    let applicationInternalId = null
    const multiApp = this.editLicenseForm.get('licenseMultiApp').value
    if(!multiApp) {
      applicationInternalId = this.editLicenseForm.get('licenseApplication').value.internalId
    }
    const license: LicenseUpdateModel = {
      internalId: this.editLicenseForm.get('licenseInternalId').value,
      companyInternalId: this.editLicenseForm.get('licenseCompanyInternalId').value,
      applicationInternalId,
      centerInternalId,
      description: this.editLicenseForm.get('licenseDescription').value,
      quantity: this.editLicenseForm.get('licenseQuantity').value,
      startDateMillis: this.editLicenseForm.get('licenseStartDateMillis').value.valueOf(),
      endDateMillis: this.editLicenseForm.get('licenseEndDateMillis').value.valueOf(),
      multiCenter,
      multiApp,
      active: this.editLicenseForm.get('active').value
    };
    this.updateLicenseSubscription = this.licenseService.updateLicense(license).pipe(
      tap(res => {
        this.licenseEditFormErrorMessage = "Licencia actualizada correctamente"
        this.licenseService.refreshLicense()
      })
    ).subscribe();
  }

  public hasError(field) {
    const formControl = this.editLicenseForm.get(field);
    if (formControl.touched && formControl.invalid) {
      if (formControl.errors.required) {
        return '* Campo obligatorio';
      }
      return '* Campo ' + field + ' inválido';
    }
    return false;
  }

}
