import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[success]'
})
export class MbButtonSuccessDirective  implements AfterViewInit {

  constructor(private eRef: ElementRef, private r2: Renderer2) { }

  ngAfterViewInit() {
    this.r2.addClass(this.eRef.nativeElement.firstChild, 'success');
  }
}
