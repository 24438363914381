import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/services/companies-services/company.service';
import { tap } from 'rxjs/internal/operators/tap';
import { CompanyModel } from 'src/app/models/company.model';
import {LicenseService} from "../../services/licenses-service/license.service";
import {LicenseModel} from "../../models/license.model";
import {UserStorageService} from "../../services/user-storage-service/user-storage.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  companies;
  enabledCompanies;
  disabledCompanies;
  licenses;
  enabledLicenses;
  disabledLicenses;
  expiredLicenses;

  constructor(
    private companyService: CompanyService,
    private licenseService: LicenseService
  ) { }

  ngOnInit() {
    this.getCompanies();
    this.getLicenses();
  }

  public getCompanies() {
    this.companyService.getCompanies().pipe(
      tap((res: CompanyModel[]) => this.companies = res.length),
      tap((res: CompanyModel[]) => this.enabledCompanies = res.filter(c => c.active === true).length),
      tap((res: CompanyModel[]) => this.disabledCompanies = res.filter(c => c.active === false).length)
    ).subscribe();
  }

  public getLicenses() {
    let currentTime = new Date().getTime();

    this.licenseService.getLicenses().pipe(
      tap((res: LicenseModel[]) => this.licenses = res.length),
      tap((res: LicenseModel[]) => this.enabledLicenses = res.filter(l => l.active === true).length),
      tap((res: LicenseModel[]) => this.disabledLicenses = res.filter(l => l.active === false).length),
      tap((res: LicenseModel[]) => this.expiredLicenses = res.filter(l => this.isExpired(l.endDateMillis)).length)
    ).subscribe();
  }

  private isExpired(millis: number) {
    return new Date().getTime() > millis
  }

}
