import { Component, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-mb-tooltip',
  templateUrl: './mb-tooltip.component.html',
  styleUrls: ['./mb-tooltip.component.scss']
})
export class MbTooltipComponent implements OnInit {

  constructor(private eRef: ElementRef) { }

  ngOnInit() {

  }

  public clickOverlay() {
    if ( this.eRef.nativeElement.lastChild.style.display === 'block') {
      this.eRef.nativeElement.lastChild.style.display = 'none';
      this.eRef.nativeElement.firstChild.style.display = 'none';
    } else {
      this.eRef.nativeElement.firstChild.style.display = 'block';
      this.eRef.nativeElement.lastChild.style.display = 'block';
    }
  }
}
