import { Injectable, isDevMode } from '@angular/core';
import { CompanyDaoService } from 'src/app/dao/companies-dao-services/company-dao.service';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';
import { catchError } from 'rxjs/internal/operators/catchError';
import { DataModel } from 'src/app/models/data.model';
import { UpdateCompanyModel } from 'src/app/models/company.model';
import { Subject, of, Observable, throwError } from 'rxjs';
import sha256 from 'crypto-js/sha256';

declare var google;

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private refreshCompanyListener = new Subject<any>();
  private refreshCompaniesListener = new Subject<any>();

  constructor(private companyDaoService: CompanyDaoService) { }

  public getCompanies() {
    return this.companyDaoService.getCompanies().pipe(
      tap((res: DataModel) => {if (isDevMode()) { console.dir(res); }}),
      map((res: DataModel) => res['data']['companyList'])
    );
  }
  public getCompany(internalId) {
    return this.companyDaoService.getCompany(internalId).pipe(
      tap((res: DataModel) => {if (isDevMode()) { console.dir(res); }}),
      map((res: DataModel) => res['data']['companyDetails'])
    );
  }
  public addNewCompany(companyForm) {
    const company = {
      active: companyForm.get('active').value,
      companyName: companyForm.get('companyComercialName').value,
      companyCif: companyForm.get('companyCif').value,
      fiscalName: companyForm.get('companyFiscalName').value,
      street: companyForm.get('companyAddress').value,
      city: companyForm.get('companyCity').value,
      zipCode: companyForm.get('companyPostalCode').value,
      province: companyForm.get('companyProvince').value,
      region: companyForm.get('companyRegion').value,
      country: companyForm.get('companyCountry').value,
      faxNumber: companyForm.get('companyFax').value,
      phoneNumber: companyForm.get('companyPhone').value,
      companyWeb: companyForm.get('companyWeb').value,
      email: companyForm.get('companyEmail').value,
      imageType: companyForm.get('companyImageType').value,
      imageSource: companyForm.get('companyImageRaw').value
    };
    const center = {
      name: companyForm.get('centerComercialName').value,
      code: companyForm.get('centerCode').value,
      cif: companyForm.get('centerCif').value,
      fiscalName: companyForm.get('centerFiscalName').value,
      fiscalAddress: companyForm.get('centerFiscalAddress').value,
      street: companyForm.get('centerAddress').value,
      city: companyForm.get('centerCity').value,
      zipCode: companyForm.get('centerPostalCode').value,
      province: companyForm.get('centerProvince').value,
      region: companyForm.get('centerRegion').value,
      country: companyForm.get('centerCountry').value,
      centerType: companyForm.get('centerType').value.internalId,
      gpsLatitude: companyForm.get('centerLatitude').value,
      gpsLongitude: companyForm.get('centerLongitude').value,
      active: companyForm.get('centerActive').value,
      visible: companyForm.get('centerVisible').value,
      faxNumber: companyForm.get('centerUserFax').value,
      phoneNumber: companyForm.get('centerUserPhone').value,
      email: companyForm.get('centerUserMail').value,
      centerContact: companyForm.get('centerUserContact').value,
      imageSource: companyForm.get('centerImageRaw').value,
      imageType: companyForm.get('centerImageType').value,
      timeZone: companyForm.get('centerTimezone').value,
    };

    const user = {
      mail: companyForm.get('userEmail').value,
      password: this.hashPassword(companyForm.get('userPassword').value),
      firstname: companyForm.get('userFirstname').value,
      lastname: companyForm.get('userLastname').value
    };

    return this.companyDaoService.addNewCompany(company, center, user).pipe(
      tap((res: DataModel) => {if (isDevMode()) { console.dir(res); }}),
      map((res: DataModel) => res['data']['companyDetails']),
      catchError(error => {
        switch (error.error.error) {
          case 'CIF_COMPANY_ALREADY_EXIST':
            return throwError('errors.cif already exists');
          case 'FAIL_EMAIL_ALREADY_EXIST':
            return throwError('errors.email already exists');
        }
      })
    );
  }
  public updateCompany(company: UpdateCompanyModel) {
    return this.companyDaoService.updateCompany(company.companyName, company.internalId, company.fiscalName, company.street, company.city, company.zipCode, company.country, company.province, company.region, company.faxNumber, company.phoneNumber, company.companyWeb, company.email).pipe(
      tap((res: DataModel) => {if (isDevMode()) { console.dir(res); }}),
      map((res: DataModel) => res['data']['success'])
    );
  }
  public updateCompanyService(internalId, serviceType, serviceActive) {
    return this.companyDaoService.updateCompanyService(internalId, serviceType, serviceActive).pipe(
      tap((res: DataModel) => {if (isDevMode()) { console.dir(res); }}),
      map((res: DataModel) => res['data']['success'])
    );
  }
  public updateCompanyImage(internalId, imageRaw, size) {
    return this.companyDaoService.updateCompanyImage(internalId, imageRaw, size).pipe(
      tap((res: DataModel) => {if (isDevMode()) { console.dir(res); }}),
      map((res: DataModel) => res['data']['success'])
    );
  }
  public deleteCompany(internalId) {
    return this.companyDaoService.deleteCompany(internalId).pipe(
      tap((res: DataModel) => {if (isDevMode()) { console.dir(res); }}),
      map((res: DataModel) => res['data']['success'])
    );
  }
  public getProductList() {
    return this.companyDaoService.getProductList().pipe(
      tap((res: DataModel) => {if (isDevMode()) { console.dir(res); }}),
      map((res: DataModel) => res['data']['mProductList'])
    );
  }
  public getCompanyProductList(internalId) {
    return this.companyDaoService.getCompanyProductList(internalId).pipe(
      tap((res: DataModel) => {if (isDevMode()) { console.dir(res); }}),
      map((res: DataModel) => res['data']['companyMproductList'])
    );
  }
  public updateCompanyProduct(companyId, productId, active) {
    return this.companyDaoService.updateCompanyProduct(companyId, productId, active).pipe(
      tap((res: DataModel) => {if (isDevMode()) { console.dir(res); }}),
      map((res: DataModel) => res['data'])
    );
  }
  public getApplicationList() {
    return this.companyDaoService.getApplicationList().pipe(
      tap((res: DataModel) => {if (isDevMode()) { console.dir(res); }}),
      map((res: DataModel) => res['data']['applicationList'])
    );
  }
  public getCenterTypeList() {
    return this.companyDaoService.getCenterTypeList().pipe(
      tap((res: DataModel) => {if (isDevMode()) { console.dir(res); }}),
      map((res: DataModel) => res['data']['centerTypeList'])
    );
  }
  public getCompanyApplicationList(internalId) {
    return this.companyDaoService.getCompanyApplicationList(internalId).pipe(
      tap((res: DataModel) => {if (isDevMode()) { console.dir(res); }}),
      map((res: DataModel) => res['data']['companyApplicationList'])
    );
  }
  public updateCompanyApplication(companyId, applicationId, active) {
    return this.companyDaoService.updateCompanyApplication(companyId, applicationId, active).pipe(
      tap((res: DataModel) => {if (isDevMode()) { console.dir(res); }}),
      map((res: DataModel) => res['data'])
    );
  }

  private hashPassword(password) {
    return sha256(password).toString().toUpperCase();
  }

  public listenRefreshCompany() {
    return this.refreshCompanyListener.asObservable();
  }

  public refreshCompany() {
    this.refreshCompanyListener.next();
  }
  public listenRefreshCompanies() {
    return this.refreshCompaniesListener.asObservable();
  }

  public refreshCompanies() {
    this.refreshCompaniesListener.next();
  }

  public getGpsLocationFromAddress(address) {
    return new Observable(
      (o) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode(
          { address },
          (results) => {
            if (results.length > 0) {
              o.next(results[0].geometry.location.toJSON());
            }
          }
        );
      }
    );
  }

  public getCompanyCountries() {
    return of([
      'España',
      'France',
      'Italia',
      'Afghanestan',
      'Shqiperia',
      'Al Jaza’ir',
      'Amerika Sāmoa',
      'Andorra',
      'Angola',
      'Anguilla',
      'Antarctica',
      'Antigua and Barbuda',
      'Argentina',
      'Hayastan',
      'Aruba',
      'Australia',
      'Österreich',
      'Azarbaycan',
      'Bahamas',
      'Al Bahrayn',
      'Bangladesh',
      'Barbados',
      'Byelarus',
      'Belgique/Belgie',
      'Belize',
      'Benin',
      'Bermuda',
      'Bhutan',
      'Bolivia',
      'Bosna i Hercegovina',
      'Botswana',
      'Brasil',
      'Negara Brunei Darussalam',
      'Bulgaria',
      'Burkina Faso',
      'Burundi',
      'Kampuchea',
      'Cameroon',
      'Canada',
      'Cabo Verde',
      'Cayman Islands',
      'Republique Centrafricaine',
      'Tchad',
      'Chile',
      'Zhong Guo',
      'Christmas Island',
      'Cocos (Keeling) Islands',
      'Colombia',
      'Comores',
      'République Démocratique du Congo',
      'République du Congo',
      'Cook Islands',
      'Costa Rica',
      'Cote d’Ivoire',
      'Hrvatska',
      'Cuba',
      'Kibris',
      'Kypros',
      'Ceska Republika',
      'Danmark',
      'Djibouti',
      'Dominica',
      'Republica Dominicana',
      'Timor',
      'Ecuador',
      'Misr',
      'El Salvador',
      'Guinea Ecuatorial',
      'Hagere Ertra',
      'Eesti Vabariik',
      'Ityop’iya',
      'Islas Malvinas',
      'Foroyar',
      'Fiji',
      'Suomen Tasavalta',
      'Guyane',
      'Polynésie Française',
      'Terres Australes et Antarctiques Françaises',
      'Gabon',
      'The Gambia',
      'Sak’art’velo',
      'Deutschland',
      'Ghana',
      'Gibraltar',
      'Great Britain',
      'Ellas or Ellada',
      'Kalaallit Nunaat',
      'Grenada',
      'Guadeloupe',
      'Guam',
      'Guatemala',
      'Guinee',
      'Guine-Bissau',
      'Guyana',
      'Haiti',
      'Status Civitatis Vaticanæ',
      'Honduras',
      'Xianggang',
      'Magyarorszag',
      'Lyoveldio Island',
      'Bharat',
      'Indonesia',
      'Iran',
      'Iraq',
      'Éire',
      'Yisra’el',
      'Jamaica',
      'Nippon',
      'Al Urdun',
      'Qazaqstan',
      'Kenya',
      'Kiribati',
      'Kiribas',
      'Choson',
      'Han-guk',
      'Albanian: : Kosova ou Kosovë',
      'Serbian: Kosovo: Косово',
      'Al Kuwayt',
      'Kyrgyz Respublikasy',
      'Lao',
      'Latvija',
      'Lubnan',
      'Lesotho',
      'Liberia',
      'Libiyah',
      'Liechtenstein',
      'Lietuva',
      'Luxembourg',
      'Letzebuerg',
      'Aomen',
      'Makedonija',
      'Madagascar',
      'Malawi',
      'Malaysia',
      'Dhivehi Raajje',
      'Mali',
      'Malta',
      'Marshall Islands',
      'Martinique',
      'Muritaniyah',
      'Mauritius',
      'Mayotte',
      'Estados Unidos Mexicanos',
      'Micronesia',
      'Moldova',
      'Monaco',
      'Mongol Uls',
      'Crna Gora',
      'Montserrat',
      'Al Maghrib',
      'Mocambique',
      'Myanma Naingngandaw',
      'Namibia',
      'Nauru',
      'Nepal',
      'Nederland/Holland',
      'Nederlandse Antillen',
      'Nouvelle-Calédonie',
      'Aotearoa',
      'Nicaragua',
      'Niger',
      'Nigeria',
      'Niue',
      'Northern Mariana Islands',
      'Norge',
      'Saltanat Uman',
      'Pakistan',
      'Belau',
      'Filastin',
      'Panama',
      'Papua Niu Gini',
      'Paraguay',
      'Peru',
      'Pilipinas',
      'Pitcairn Island',
      'Polska',
      'Portugal',
      'Puerto Rico',
      'Dawlat Qatar',
      'Ile de la Réunion',
      'Romania',
      'Rossiya',
      'Rwanda',
      'Saint Lucia',
      'Samoa',
      'San Marino',
      'Sao Tome e Principe',
      'Al Arabiyah as Suudiyah',
      'Senegal',
      'Srbija',
      'Seychelles',
      'Sierra Leone',
      'Singapore',
      'Slovensko',
      'Slovenija',
      'Solomon Islands',
      'Somalia',
      'South Africa',
      'South Sudan',
      'España',
      'Sri Lanka',
      'As-Sudan',
      'Suriname',
      'Swaziland',
      'Sverige',
      'Schweiz (German)',
      'Suisse (French)',
      'Svizzera (Italian)',
      'Suriyah',
      'T’ai-wan',
      'Jumhurii Tojikiston',
      'Tanzania',
      'Prathet Thai',
      'Bod',
      'Timor',
      'Republique Togolaise',
      'Tokelau',
      'Tonga',
      'Trinidad',
      'Tobago',
      'Tunis',
      'Turkiye',
      'Turkmenistan',
      'Turks and Caicos Islands',
      'Tuvalu',
      'Uganda',
      'Ukrayina',
      'Al Imarat al Arabiyah al Muttahidah',
      'United Kingdom',
      'United States',
      'Republica Oriental del Uruguay',
      'Uzbekiston Respublikasi',
      'Vanuatu',
      'Status Civitatis Vaticanæ',
      'Venezuela',
      'Viet Nam',
      'Virgin Islands',
      'Wallis et Futuna',
      'Aṣ-Ṣaḥrā’ al-Gharbīyah',
      'Al Yaman',
      'Zambia',
      'Zimbabwe'
    ]);
  }
}
