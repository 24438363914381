import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-mb-field',
  templateUrl: './mb-field.component.html',
  styleUrls: ['./mb-field.component.scss']
})
export class MbFieldComponent implements OnInit {

  @Input() title;
  @Input() preicon: boolean;
  @Input() posticon: boolean;

  constructor() { }

  ngOnInit() {
  }

}
