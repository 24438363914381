import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatInputModule} from "@angular/material/input";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HeaderAccountComponent } from './header/header-account/header-account.component';
import { HeaderNotificationsComponent } from './header/header-notifications/header-notifications.component';
import { HeaderSearchComponent } from './header/header-search/header-search.component';
import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout/layout.component';
import { SidenavLogoComponent } from './sidenav/sidenav-logo/sidenav-logo.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SigninCardComponent } from './signin/signin-card/signin-card.component';
import { SigninComponent } from './signin/signin.component';
import { SharedComponentsModule } from './shared-components/shared-components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { UserStorageService } from './services/user-storage-service/user-storage.service';
import { CompaniesCompaniesComponent } from './components/companies-components/companies-companies/companies-companies.component';
import { CompaniesCompaniesDashboardComponent } from './components/companies-components/companies-companies-dashboard/companies-companies-dashboard.component';
import { CompaniesCompanyDashboardComponent } from './components/companies-components/companies-company-dashboard/companies-company-dashboard.component';
import { CompaniesCompanyInfoCardComponent } from './components/companies-components/companies-company-info-card/companies-company-info-card.component';
import { CompaniesCompanyComponent } from './components/companies-components/companies-company/companies-company.component';
import { CompaniesCompanyServicesCardComponent } from './components/companies-components/companies-company-services-card/companies-company-services-card.component';
import { CompaniesCompaniesListCardComponent } from './components/companies-components/companies-companies-list-card/companies-companies-list-card.component';
import { CompaniesCompanyImageCardComponent } from './components/companies-components/companies-company-image-card/companies-company-image-card.component';
import { CompaniesNewCompanyDashboardComponent } from './components/companies-components/companies-new-company-dashboard/companies-new-company-dashboard.component';
import { CompaniesNewCompanyCreationCardComponent } from './components/companies-components/companies-new-company-creation-card/companies-new-company-creation-card.component';
import { FilterApplicationsByProductsPipe } from './pipes/filterApplicationsByProducts-pipe/filter-applications-by-products.pipe';
import { DateUnixTransformerPipe } from './pipes/date-unix-transformer-pipe/date-unix-transformer.pipe';
import { LicensesLicensesComponent } from './components/licenses-components/licenses-licenses/licenses-licenses.component';
import { LicensesLicensesDashboardComponent } from './components/licenses-components/licenses-licenses-dashboard/licenses-licenses-dashboard.component';
import { LicensesLicensesListCardComponent } from './components/licenses-components/licenses-licenses-list-card/licenses-licenses-list-card.component';
import { LicensesLicenseComponent } from './components/licenses-components/licenses-license/licenses-license.component';
import { LicensesLicenseDashboardComponent } from './components/licenses-components/licenses-license-dashboard/licenses-license-dashboard.component';
import { LicensesLicenseInfoCardComponent } from './components/licenses-components/licenses-license-info-card/licenses-license-info-card.component';
import { LicensesNewLicenseDashboardComponent } from './components/licenses-components/licenses-new-license-dashboard/licenses-new-license-dashboard.component';
import { LicensesNewLicenseCreationCardComponent } from './components/licenses-components/licenses-new-license-creation-card/licenses-new-license-creation-card.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    LayoutComponent,
    SidenavComponent,
    HeaderComponent,
    SidenavLogoComponent,
    HeaderAccountComponent,
    HeaderNotificationsComponent,
    SigninCardComponent,
    HeaderSearchComponent,
    DashboardComponent,
    CompaniesCompaniesComponent,
    CompaniesCompaniesDashboardComponent,
    CompaniesCompaniesListCardComponent,
    CompaniesCompanyInfoCardComponent,
    CompaniesCompanyDashboardComponent,
    CompaniesCompanyComponent,
    CompaniesCompanyServicesCardComponent,
    CompaniesCompanyImageCardComponent,
    CompaniesNewCompanyDashboardComponent,
    CompaniesNewCompanyCreationCardComponent,
    FilterApplicationsByProductsPipe,
    DateUnixTransformerPipe,
    LicensesLicensesComponent,
    LicensesLicensesDashboardComponent,
    LicensesLicensesListCardComponent,
    LicensesLicenseComponent,
    LicensesLicenseDashboardComponent,
    LicensesLicenseInfoCardComponent,
    LicensesNewLicenseDashboardComponent,
    LicensesNewLicenseCreationCardComponent
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        AppRoutingModule,
        ReactiveFormsModule,
        SharedComponentsModule,
        HttpClientModule,
        MatInputModule,
        MatMomentDateModule
    ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    UserStorageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
