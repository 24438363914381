import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { CompanyModel } from 'src/app/models/company.model';
import { Observable } from 'rxjs/internal/Observable';
import { CompanyService } from 'src/app/services/companies-services/company.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-companies-company-image-card',
  templateUrl: './companies-company-image-card.component.html',
  styleUrls: ['./companies-company-image-card.component.scss']
})
export class CompaniesCompanyImageCardComponent implements OnInit {

  @ViewChild('uploadInput', { static: false }) uploadInput: ElementRef;
  @Input() company: CompanyModel;
  @Input() size: string;
  updateAllSizes: boolean;
  updateCompanyImageImageSubscription: Subscription;
  updateImageForm = new FormGroup({
    updateAllSizes: new FormControl(false)
  });

  constructor(private companyService: CompanyService) { }

  ngOnInit() {
  }

  public updateImage(imageRaw, size) {
    this.updateCompanyImageImageSubscription = this.companyService.updateCompanyImage(this.company.internalId, imageRaw, size).pipe(
      tap(res => this.companyService.refreshCompany()),
      tap(res => this.updateImageForm.patchValue({ updateAllSizes: false }))
    ).subscribe();
  }

  public clickUploadButton() {
    this.uploadInput.nativeElement.click();
  }

  public uploadInputChanged(event) {
    const size = this.updateImageForm.get('updateAllSizes').value ? null : this.size;

    const files = this.uploadInput.nativeElement.files;
    this.toBase64(files[0]).subscribe(
      (res) => {
        this.updateImage(res, size);
      }
    );
  }

  private toBase64(file) {
    return new Observable(
      (o) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          o.next(reader.result);
        };
        reader.onerror = (error) => {
          o.error(error);
        };
      }
    );
  }
}
