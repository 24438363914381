import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {UserStorageService} from "../services/user-storage-service/user-storage.service";

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  userEmail;

  constructor(private router: Router,
              private userStorageService: UserStorageService
  ) { }

  ngOnInit() {
    this.userEmail = this.userStorageService.getUserData()['email']
  }

  public goto(destiny) {
    this.router.navigate([destiny]);
  }
}
