import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MbCardComponent } from './mb-card/mb-card.component';
import { MbFieldComponent } from './mb-field/mb-field.component';
import { MbFieldPreiconDirective } from './mb-field/mb-field-preicon-directive/mb-field-preicon.directive';
import { MbFieldPosticonDirective } from './mb-field/mb-field-posticon-directive/mb-field-posticon.directive';
import { MbFieldInlineDirective } from './mb-field/mb-field-inline-directive/mb-field-inline.directive';
import { MbFieldPillDirective } from './mb-field/mb-field-pill-directive/mb-field-pill.directive';
import { MbButtonComponent } from './mb-button/mb-button.component';
import { MbFieldFullwidthDirective } from './mb-field/mb-field-fullwidth-directive/mb-field-fullwidth.directive';
import { MbButtonFlatDirective } from './mb-button/mb-button-flat-directive/mb-button-flat.directive';
import { MbButtonErrorDirective } from './mb-button/mb-button-error-directive/mb-button-error.directive';
import { MbButtonSuccessDirective } from './mb-button/mb-button-success-directive/mb-button-success.directive';
import { MbButtonWarningDirective } from './mb-button/mb-button-warning-directive/mb-button-warning.directive';
import { MbCollapseComponent } from './mb-collapse/mb-collapse.component';
import { MbTableComponent } from './mb-table/mb-table.component';
import { MbTooltipComponent } from './mb-tooltip/mb-tooltip.component';
import { MbTooltipDirective } from './mb-tooltip/mb-tooltip-directive/mb-tooltip.directive';
import { InputComponent } from './input/input.component';

@NgModule({
  declarations: [
    MbCardComponent,
    MbFieldComponent,
    MbFieldPreiconDirective,
    MbFieldPosticonDirective,
    MbFieldPillDirective,
    MbButtonComponent,
    MbFieldFullwidthDirective,
    MbButtonFlatDirective,
    MbButtonErrorDirective,
    MbButtonSuccessDirective,
    MbButtonWarningDirective,
    MbFieldInlineDirective,
    MbCollapseComponent,
    MbTableComponent,
    MbTooltipComponent,
    MbTooltipDirective,
    InputComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MbCardComponent,
    MbFieldComponent,
    MbFieldPreiconDirective,
    MbFieldPosticonDirective,
    MbFieldPillDirective,
    MbFieldFullwidthDirective,
    MbButtonFlatDirective,
    MbButtonComponent,
    MbButtonErrorDirective,
    MbButtonSuccessDirective,
    MbButtonWarningDirective,
    MbFieldInlineDirective,
    MbCollapseComponent,
    MbTableComponent,
    MbTooltipComponent,
    MbTooltipDirective,
    InputComponent
  ],
  entryComponents: []
})
export class SharedComponentsModule { }
