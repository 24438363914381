import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-companies-companies',
  templateUrl: './companies-companies.component.html',
  styleUrls: ['./companies-companies.component.scss']
})
export class CompaniesCompaniesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
