import { Component, OnInit } from '@angular/core';
import { UserStorageService } from 'src/app/services/user-storage-service/user-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-account',
  templateUrl: './header-account.component.html',
  styleUrls: ['./header-account.component.scss']
})
export class HeaderAccountComponent implements OnInit {

  userShortcut;

  constructor(private userStorageService: UserStorageService,
              private router: Router) { }

  ngOnInit() {
    this.userShortcut = this.userStorageService.getUserData()['email'] === 'fgarcia@m-shop.mobi' ? "FG" : "LI";
  }

  public signout() {
    this.userStorageService.deleteAllItems();
    this.router.navigate(['/signin']);
  }
}
