import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/services/companies-services/company.service';
import { tap } from 'rxjs/internal/operators/tap';
import { ProductModel } from 'src/app/models/product.model';
import { ApplicationModel } from 'src/app/models/application.model';
import { CenterTypeModel } from 'src/app/models/centertype.model';
import { TIMEZONES } from 'src/assets/timezones/timezones';

@Component({
  selector: 'app-companies-new-company-dashboard',
  templateUrl: './companies-new-company-dashboard.component.html',
  styleUrls: ['./companies-new-company-dashboard.component.scss']
})
export class CompaniesNewCompanyDashboardComponent implements OnInit {

  countries: string[];
  products: ProductModel[];
  applications: ApplicationModel[];
  centerTypes: CenterTypeModel[];
  timezones;

  constructor(private companyService: CompanyService) { 
    this.timezones = TIMEZONES;
  }

  ngOnInit() {
    this.getCompanyCountries();
    this.getProductList();
    this.getApplicationList();
    this.getCenterTypeList();
  }

  public getCompanyCountries() {
    this.companyService.getCompanyCountries().pipe(
      tap(res => this.countries = res)
    ).subscribe();
  }

  public getProductList() {
    this.companyService.getProductList().pipe(
      tap((res: ProductModel[]) => this.products = res)
    ).subscribe();
  }

  public getApplicationList() {
    this.companyService.getApplicationList().pipe(
      tap((res: ApplicationModel[]) => this.applications = res)
    ).subscribe();
  }

  public getCenterTypeList() {
    this.companyService.getCenterTypeList().pipe(
      tap((res: CenterTypeModel[]) => this.centerTypes = res)
    ).subscribe();
  }
}
