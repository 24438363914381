import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserStorageService } from 'src/app/services/user-storage-service/user-storage.service';

@Component({
  selector: 'app-signin-card',
  templateUrl: './signin-card.component.html',
  styleUrls: ['./signin-card.component.scss']
})
export class SigninCardComponent implements OnInit {

  signInForm = new FormGroup({
    email: new FormControl(null, Validators.required),
    password: new FormControl(null, Validators.required)
  });

  constructor(private router: Router,
              private userStorageService: UserStorageService) { }

  ngOnInit() {
  }

  public signIn() {
    if ((this.signInForm.get('email').value === 'fgarcia@m-shop.mobi') && (this.signInForm.get('password').value === 'mShop0309')) {
      this.userStorageService.setUserData({email: 'fgarcia@m-shop.mobi', password: 'mShop0309'});
      this.router.navigate(['/']);
    } else if ((this.signInForm.get('email').value === 'licenses@m-shop.mobi') && (this.signInForm.get('password').value === 'licenses0309')) {
      this.userStorageService.setUserData({email: 'licenses@m-shop.mobi', password: 'licenses0309'});
      this.router.navigate(['/']);
    }
  }
}
