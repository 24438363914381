import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { CompanyModel } from 'src/app/models/company.model';
import { CompanyService } from 'src/app/services/companies-services/company.service';
import { map } from 'rxjs/internal/operators/map';
import { Subscription } from 'rxjs';
import { ProductModel } from 'src/app/models/product.model';
import { ApplicationModel } from 'src/app/models/application.model';
import { tap } from 'rxjs/internal/operators/tap';

@Component({
  selector: 'app-companies-company-services-card',
  templateUrl: './companies-company-services-card.component.html',
  styleUrls: ['./companies-company-services-card.component.scss']
})
export class CompaniesCompanyServicesCardComponent implements OnInit {

  @Input() company: CompanyModel;
  @Input() products;
  @Input() applications;
  @Input() companyProducts;
  @Input() companyApplications;
  showProductsForm;
  showApplicationsForm;
  productsForm = new FormGroup({});
  applicationsForm = new FormGroup({});

  servicesCompanyForm = new FormGroup({
    mBroadcast: new FormControl(null),
    mTurn: new FormControl(null),
    mHelp: new FormControl(null)
  });
  updateCompanyServiceSubscription: Subscription;

  constructor(private companyService: CompanyService) { }

  ngOnInit() {
    this.resetServicesCompanyForm(this.company);
    this.setProductsForm();
    this.setApplicationsForm();
  }

  public updateProduct(product) {
    this.companyService.updateCompanyProduct(this.company.internalId, product.mproductCode, this.productsForm.get(product.mproductCode).value).pipe(
      tap(o => {
        if (!this.productsForm.get(product.mproductCode).value) {
          Object.entries(this.applicationsForm.value).forEach(p => this.applicationsForm.get(p[0]).patchValue(false));
          this.getApplicationsByMainService(product.mainService).forEach(a => this.companyService.updateCompanyApplication(this.company.internalId, a.applicationName, false).pipe().subscribe());
        }
      })
    ).subscribe();
  }

  public updateApplication(application) {
    this.companyService.updateCompanyApplication(this.company.internalId, application.applicationName, this.applicationsForm.get(application.applicationName).value).pipe(
      tap(o => this.companyService.refreshCompany())
    ).subscribe();
  }

  public updateCompanyService(service) {
    this.updateCompanyServiceSubscription = this.companyService.updateCompanyService(this.company.internalId, service, this.servicesCompanyForm.get(service).value).pipe(
      map(res => this.companyService.refreshCompany())
    ).subscribe();
  }

  public getActiveProducts() {
    const enabledProducts = [];
    if (this.productsForm && (Object.entries(this.productsForm.value).length > 0)) {
      Object.entries(this.productsForm.value).forEach(c => {
        if (c[1]) {
          enabledProducts.push(this.products.find(p => p.mproductCode === c[0]));
        }
      });
      return enabledProducts;
    }
    return [];
  }

  private getApplicationsByMainService(mainService) {
    return this.applications.filter(a => a.mainService === mainService);
  }

  private setProductsForm() {
    this.products.forEach((p: ProductModel) => {
      const control = new FormControl(false);
      this.productsForm.addControl(p.mproductCode, control);
    });
    this.companyProducts.forEach(cp => {
      this.productsForm.patchValue({ [cp.mproductCode]: true });
    });
    this.showProductsForm = true;
  }

  private setApplicationsForm() {
    this.applications.forEach((a: ApplicationModel) => {
      const control = new FormControl(false);
      this.applicationsForm.addControl(a.applicationName, control);
    });
    this.companyApplications.forEach(cp => {
      this.applicationsForm.patchValue({ [cp.applicationName]: true });
    });
    this.showApplicationsForm = true;
  }

  private resetServicesCompanyForm(data: CompanyModel) {
    this.servicesCompanyForm.patchValue({
      mBroadcast: data.mbroadcastService,
      mHelp: data.mhelpService,
      mTurn: data.mturnService
    });
  }
}
