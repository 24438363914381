import { Directive, Input, Renderer2, AfterViewInit, ElementRef } from '@angular/core';

@Directive({
  selector: '[tooltip]'
})
export class MbTooltipDirective implements AfterViewInit {

  @Input() tooltip;

  constructor(private eRef: ElementRef, private r2: Renderer2) { }

  ngAfterViewInit() {
    const tooltip = this.r2.parentNode(this.eRef.nativeElement).querySelectorAll('.' + this.tooltip)[0].firstChild;
    const overlay = this.r2.parentNode(this.eRef.nativeElement).querySelectorAll('.' + this.tooltip)[0].lastChild;
    tooltip.style.top = this.eRef.nativeElement.style.clientHeight + 'px';
    tooltip.style.right = '0';
    this.r2.listen(this.eRef.nativeElement, 'click', (e) => {
      if (tooltip.style.display === 'block') {
        tooltip.style.display = 'none';
        overlay.style.display = 'none';
      } else {
        overlay.style.display = 'block';
        tooltip.style.display = 'block';
      }
    });
  }

}
