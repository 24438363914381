import { Directive, AfterViewInit, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[posticon]'
})
export class MbFieldPosticonDirective implements AfterViewInit {

  constructor(private eRef: ElementRef, private r2: Renderer2) { }

  ngAfterViewInit() {
    this.r2.addClass(this.eRef.nativeElement.firstChild, 'posticon');
  }
}
