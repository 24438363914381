import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './signin/signin.component';
import { LayoutComponent } from './layout/layout.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UserAccessGuard } from './guards/user-access-guard/user-access.guard';
import { CompaniesCompaniesComponent } from './components/companies-components/companies-companies/companies-companies.component';
import { CompaniesCompaniesDashboardComponent } from './components/companies-components/companies-companies-dashboard/companies-companies-dashboard.component';
import { CompaniesCompanyDashboardComponent } from './components/companies-components/companies-company-dashboard/companies-company-dashboard.component';
import { CompaniesCompanyComponent } from './components/companies-components/companies-company/companies-company.component';
import { CompaniesNewCompanyDashboardComponent } from './components/companies-components/companies-new-company-dashboard/companies-new-company-dashboard.component';
import { LicensesLicensesComponent } from "./components/licenses-components/licenses-licenses/licenses-licenses.component";
import { LicensesLicensesDashboardComponent } from "./components/licenses-components/licenses-licenses-dashboard/licenses-licenses-dashboard.component";
import {LicensesLicenseComponent} from "./components/licenses-components/licenses-license/licenses-license.component";
import {
  LicensesLicenseDashboardComponent
} from "./components/licenses-components/licenses-license-dashboard/licenses-license-dashboard.component";
import {
  LicensesNewLicenseDashboardComponent
} from "./components/licenses-components/licenses-new-license-dashboard/licenses-new-license-dashboard.component";


const routes: Routes = [
  { path: 'signin', component: SigninComponent },
  { path: '', component: LayoutComponent, children: [
    { path: '', component: DashboardComponent, canActivate: [UserAccessGuard], pathMatch: 'full' },
    { path: 'companies', component: CompaniesCompaniesComponent, children: [
      { path: '', component: CompaniesCompaniesDashboardComponent, canActivate: [UserAccessGuard], pathMatch: 'full' }
    ] },
    { path: 'company', component: CompaniesCompanyComponent, children: [
      { path: '', component: CompaniesNewCompanyDashboardComponent, canActivate: [UserAccessGuard] },
      { path: ':companyId', component: CompaniesCompanyDashboardComponent, canActivate: [UserAccessGuard] }
    ] },
    { path: 'licenses', component: LicensesLicensesComponent, children: [
      { path: '', component: LicensesLicensesDashboardComponent, canActivate: [UserAccessGuard], pathMatch: 'full' }
    ] },
    { path: 'license', component: LicensesLicenseComponent, children: [
      { path: '', component: LicensesNewLicenseDashboardComponent, canActivate: [UserAccessGuard] },
      { path: ':licenseId', component: LicensesLicenseDashboardComponent, canActivate: [UserAccessGuard] }
    ] }
  ] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'top', enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
