import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-companies-company',
  templateUrl: './companies-company.component.html',
  styleUrls: ['./companies-company.component.scss']
})
export class CompaniesCompanyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
