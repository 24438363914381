import { Directive, HostBinding, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[preicon]'
})
export class MbFieldPreiconDirective implements AfterViewInit {

  constructor(private eRef: ElementRef, private r2: Renderer2) { }

  ngAfterViewInit() {
    this.r2.addClass(this.eRef.nativeElement.firstChild, 'preicon');
  }
}
