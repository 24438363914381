import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService } from 'src/app/services/security-service/security.service';

@Injectable()
export class UserStorageService {

  private MCOMPANY_USERDATA_ID = 'mcom_ud';

  constructor(private router: Router,
              private securityService: SecurityService) { }


  public setUserData(userData) {
    if (userData) {
      try {
        localStorage.setItem(this.MCOMPANY_USERDATA_ID, this.securityService.encrypt(JSON.stringify(userData)));
        return true;
      } catch (e) {
        console.log('Fail saving user data in browser. ' + e);
        return false;
      }
    } else {
      return false;
    }
  }


  public getUserData() {
    if (localStorage.getItem(this.MCOMPANY_USERDATA_ID)) {
      try {
        return JSON.parse(this.securityService.decrypt(localStorage.getItem(this.MCOMPANY_USERDATA_ID)));
      } catch (e) {
        console.log('Fail retrieving user data from browser. ' + e);
        return null;
      }
    } else {
      return null;
    }
  }

  public deleteAllItems() {
    localStorage.removeItem(this.MCOMPANY_USERDATA_ID);
  }

  public checkSession() {
    if (this.getUserData()) {
      return true;
    } else {
      return false;
    }
  }

}
