import { Component, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

  @Input() title;

  @Input() rightItem;
  @Input() misfit: boolean;
  @Input() preicon: boolean;
  @Input() posticon: boolean;
  @Input() lowercase: boolean;

  constructor(eRef: ElementRef, r2: Renderer2) {
    if (this.preicon) { r2.addClass(eRef.nativeElement.firstChild, 'preicon'); }
  }

  ngOnInit() { }

}
