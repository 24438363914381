import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/services/companies-services/company.service';
import { CompanyModel } from 'src/app/models/company.model';
import { tap } from 'rxjs/internal/operators/tap';

@Component({
  selector: 'app-companies-companies-list-card',
  templateUrl: './companies-companies-list-card.component.html',
  styleUrls: ['./companies-companies-list-card.component.scss']
})
export class CompaniesCompaniesListCardComponent implements OnInit {

  companies;

  constructor(private companyService: CompanyService) { }

  ngOnInit() {
    this.listenRefreshCompanies();
    this.getCompanies();
  }

  public getCompanies() {
    this.companyService.getCompanies().pipe(
      tap((res: CompanyModel[]) => this.companies = res)
    ).subscribe();
  }

  public deleteCompany(company) {
    this.companyService.deleteCompany(company.internalId).pipe(
      tap(res => this.companyService.refreshCompanies())
    ).subscribe();
  }

  private listenRefreshCompanies() {
    this.companyService.listenRefreshCompanies().subscribe(
      (res) => {
        this.getCompanies();
      }
    );
  }
}
