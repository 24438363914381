import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateUnixTransformer'
})
export class DateUnixTransformerPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (typeof value === 'number' ) {
      if (args && (args === 'false')) {
        return moment.unix(value / 1000).utc().format('DD/MM/YYYY');
      } else if (args && (args === 'true')) {
        return moment.unix(value / 1000).utc().format('HH:mm:ss');
      } else if (args === undefined) {
        return moment.unix(value / 1000).utc().format('DD/MM/YYYY HH:mm:ss');
      }
    }
    return value;
  }

}
