import { Component, OnInit } from '@angular/core';
import {CompanyService} from "../../../services/companies-services/company.service";
import {tap} from "rxjs/internal/operators/tap";
import {CompanyModel} from "../../../models/company.model";
import {LicenseModel} from "../../../models/license.model";
import {LicenseService} from "../../../services/licenses-service/license.service";

@Component({
  selector: 'app-licenses-licenses-list-card',
  templateUrl: './licenses-licenses-list-card.component.html',
  styleUrls: ['./licenses-licenses-list-card.component.scss']
})
export class LicensesLicensesListCardComponent implements OnInit {

  licenses: LicenseModel[];

  constructor(private licenseService: LicenseService) { }

  ngOnInit() {
    this.listenRefreshLicenses();
    this.getLicenses();
  }

  public getLicenses() {
    this.licenseService.getLicenses().pipe(
      tap((res: LicenseModel[]) => this.licenses = res)
    ).subscribe();
  }

  public isExpired(millis: number) {
    return new Date().getTime() > millis
  }

  public deleteLicense(license) {
    this.licenseService.deleteLicense(license.internalId).pipe(
      tap(res => this.licenseService.refreshLicenses())
    ).subscribe();
  }

  private listenRefreshLicenses() {
    this.licenseService.listenRefreshLicenses().subscribe(
      (res) => {
        this.getLicenses();
      }
    );
  }

}
