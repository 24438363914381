import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterApplicationsByProducts'
})
export class FilterApplicationsByProductsPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {

    if (value) {
      const z = value.filter(v => args[0].find(a => a.mainService === v.mainService));
      return z;
    }
    return value;
  }

}
