import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {DataModel} from 'src/app/models/data.model';

@Injectable({
  providedIn: 'root'
})
export class LicenseDaoService {

  private BASE_URL = environment.apiURL;
  private OPTIONS = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8'
    })
  };

  constructor(private http: HttpClient) { }

  public getLicenses() {
    const options = {};
    Object.assign(options, this.OPTIONS);
    return this.http.get<DataModel>(this.BASE_URL + 'private/v2.0/companyFormMaintenance/license/list', options);
  }

  public getLicense(internalId: string) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    options['params'] = { internalId };
    return this.http.get<DataModel>(this.BASE_URL + 'private/v2.0/companyFormMaintenance/license', options);
  }

  public getCompanies() {
    const options = {};
    Object.assign(options, this.OPTIONS);
    return this.http.get<DataModel>(this.BASE_URL + 'private/v2.0/companyFormMaintenance/license/companies', options);
  }

  public getCenters(companyInternalId: string) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    options['params'] = { companyInternalId };
    return this.http.get<DataModel>(this.BASE_URL + 'private/v2.0/companyFormMaintenance/license/centers', options);
  }

  public getApplications(companyInternalId: string) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    options['params'] = { companyInternalId };
    return this.http.get<DataModel>(this.BASE_URL + 'private/v2.0/companyFormMaintenance/license/applications', options);
  }

  public addNewLicense(companyInternalId, applicationInternalId, centerInternalId, description, quantity, startDateMillis, endDateMillis, multiCenter, multiApp, active) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    const item = { companyInternalId, applicationInternalId, centerInternalId, description, quantity, startDateMillis, endDateMillis, multiCenter, multiApp, active };
    return this.http.post<DataModel>(this.BASE_URL + 'private/v2.0/companyFormMaintenance/license', item, options);
  }


  public updateLicense(internalId, companyInternalId, applicationInternalId, centerInternalId, description, quantity, startDateMillis, endDateMillis, multiCenter, multiApp, active) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    const item = { internalId, companyInternalId, applicationInternalId, centerInternalId, description, quantity, startDateMillis, endDateMillis, multiCenter, multiApp, active };
    return this.http.put<DataModel>(this.BASE_URL + 'private/v2.0/companyFormMaintenance/license', item, options);
  }

  public deleteLicense(internalId) {
    const options = {};
    Object.assign(options, this.OPTIONS);
    options['body'] = { internalId };
    return this.http.delete<DataModel>(this.BASE_URL + 'private/v2.0/companyFormMaintenance/license', options);
  }

}
