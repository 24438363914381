import {Component, Input, OnInit} from '@angular/core';
import {
  LicenseApplicationModel,
  LicenseCenterModel,
  LicenseCompanyModel,
  LicenseModel
} from "../../../models/license.model";
import {LicenseService} from "../../../services/licenses-service/license.service";
import {ActivatedRoute} from "@angular/router";
import {tap} from "rxjs/internal/operators/tap";
import {CompanyModel} from "../../../models/company.model";

@Component({
  selector: 'app-licenses-license-dashboard',
  templateUrl: './licenses-license-dashboard.component.html',
  styleUrls: ['./licenses-license-dashboard.component.scss']
})
export class LicensesLicenseDashboardComponent implements OnInit {

  license: LicenseModel;
  centers: LicenseCenterModel[];
  applications: LicenseApplicationModel[];

  constructor(private activatedRoute: ActivatedRoute,
              private licenseService: LicenseService) { }

  ngOnInit() {
    this.listenRefreshLicense();
    this.getLicense()
  }

  private getLicense() {
    const licenseId = this.activatedRoute.snapshot.params['licenseId'];
    this.licenseService.getLicense(licenseId).pipe(
      tap((res: LicenseModel) => this.license = res),
      tap((res: LicenseModel) => this.getCenters(this.license.companyInternalId)),
      tap((res: LicenseModel) => this.getApplications(this.license.companyInternalId))
    ).subscribe();
  }

  public getCenters(internalId: string) {
    this.licenseService.getCenters(internalId).pipe(
      tap((res: LicenseCenterModel[]) => this.centers = res)
    ).subscribe();
  }

  public getApplications(internalId: string) {
    this.licenseService.getApplications(internalId).pipe(
      tap((res: LicenseApplicationModel[]) => this.applications = res)
    ).subscribe();
  }

  private listenRefreshLicense() {
    this.licenseService.listenRefreshLicense().subscribe(
      (res) => {
        this.getLicense();
      }
    );
  }

}
