import { Directive, Renderer2, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[pill]'
})
export class MbFieldPillDirective implements AfterViewInit {

  constructor(private eRef: ElementRef, private r2: Renderer2) { }

  ngAfterViewInit() {
    this.r2.addClass(this.eRef.nativeElement.firstChild, 'pill');
  }

}
